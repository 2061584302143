import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MortgageCalculator from "../../components/MortgageCalculator";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Hausfinanzierung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Hausfinanzierung – Die Basics einfach erklärt" showCalc={true} />
            <Article>
                <h2>Hausfinanzierung – Die Basics einfach erklärt</h2>
                <p>
                    Du bist nun endlich an dem Punkt deines Lebens angelangt, an dem du deinen Traum vom Eigenheim
                    verwirklichen willst &ndash; die Welt der{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    ist dir allerdings noch einigerma&szlig;en fremd? Keine Sorge: Im folgenden Beitrag erkl&auml;ren
                    wir dir, worauf es bei der Hausfinanzierung ganz grundlegend ankommt.
                </p>
                <hr />

                <h2>Zuerst die Planung: Erstelle eine Haushaltsrechnung</h2>
                <p>
                    Vor jeder gr&ouml;&szlig;eren Investition ist es ratsam, eine Haushaltsrechnung zu erstellen, um
                    denjenigen Teil deines Einkommens zu bestimmen, &uuml;ber den du frei verf&uuml;gen kannst &ndash;
                    den du also nicht f&uuml;r laufende Kosten aufwenden musst. Du berechnest ihn, indem du deine
                    monatlichen Ausgaben von deinen Einnahmen abziehst.
                </p>
                <hr />

                <h3>Schritt 1: Monatliche Einnahmen berechnen</h3>
                <p>Addiere also zun&auml;chst s&auml;mtliche Eink&uuml;nfte wie</p>
                <ul>
                    <li>dein Nettoeinkommen (Einkommen exklusive Steuern und Sozialabgaben)</li>
                    <li>Eink&uuml;nfte durch Beihilfen (z.B. Kinder-, Familien- oder Studienbeihilfe)</li>
                    <li>etwaige Mieteinnahmen</li>
                    <li>allf&auml;llige Renten</li>
                </ul>
                <p>
                    Dabei solltest du dir dar&uuml;ber im Klaren sein, dass besonders Beihilfen w&auml;hrend der
                    Kreditlaufzeit wegfallen k&ouml;nnen, wodurch sich dein Einkommen verringern kann.
                </p>
                <h3>Schritt 2: Monatliche Ausgaben ermitteln</h3>
                <p>
                    Sobald du s&auml;mtliche Einnahmen addiert hast, geht es an den zweiten Schritt: die Berechnung
                    deiner monatlichen Ausgaben. Auch daf&uuml;r musst du nur die Addition beherrschen &ndash; in diesem
                    Fall die deiner Fix- und Lebenserhaltungskosten. Zu diesen z&auml;hlen:
                </p>
                <ul>
                    <li>Miete sowie Betriebs- und Energiekosten</li>
                    <li>Kosten f&uuml;r Telefon, Internet und Rundfunk</li>
                    <li>
                        Mobilit&auml;tskosten (Kosten f&uuml;r eigene Fahrzeuge und/oder &ouml;ffentliche
                        Verkehrsmittel)
                    </li>
                    <li>Versicherungen</li>
                    <li>Abonnements von Zeitschriften, Streamingdiensten etc.</li>
                    <li>Unterhaltszahlungen, Verpflegung und Kleidung</li>
                    <li>und auch alle Ausgaben f&uuml;r deine Hobbies</li>
                </ul>
                <h3>Schritt 3: Eine letzte Subtraktion</h3>
                <p>
                    Im letzten Schritt ziehst du die Summe deiner Ausgaben von der Gesamtheit deiner Eink&uuml;nfte ab
                    &ndash; und voi&shy;l&agrave;: du hast soeben dein frei verf&uuml;gbares Einkommen berechnet! Wie du
                    auf Basis dieses Betrags eine passende{" "}
                    <Link to="/artikel/kredit-tipps/" target="_blank" rel="noreferrer noopener">
                        Kreditsumme
                    </Link>{" "}
                    ermittelst, erkl&auml;ren wir dir im folgenden Abschnitt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Angebot sichern!
                    </a>
                </p>
                <hr />

                <h2>Von der Haushaltsrechnung zur Kreditrate</h2>
                <p>
                    Kennt man erst einmal den Betrag, der monatlich zur freien Verf&uuml;gung steht, dann f&auml;llt
                    auch die Berechnung einer leistbaren Kreditrate nicht schwer. Dabei solltest du jedoch unbedingt
                    Reserven einplanen &ndash; kann es doch zu unerwarteten Kosten, etwa f&uuml;r Reparaturen oder
                    Neuanschaffungen, kommen. Auch in diesen F&auml;llen solltest du in der Lage sein, deine monatliche
                    Rate problemlos zu begleichen. Finanzieller Spielraum ist auch dann wichtig, wenn du dich f&uuml;r
                    einen variabel verzinsten Kredit entscheidest. Der Zinssatz ist bei diesem Modell aufgrund des
                    niedrigen Niveaus, auf dem sich der{" "}
                    <Link to="/artikel/leitzins/" target="_blank" rel="noreferrer noopener">
                        Leitzins
                    </Link>{" "}
                    der Europ&auml;ischen Zentralbank seit l&auml;ngerem befindet, aktuell zwar vergleichsweise niedrig.
                    Bei entsprechenden Entwicklungen auf dem Finanzmarkt kann er jedoch jederzeit wieder ansteigen,
                    wodurch &nbsp;deine monatlichen Raten sich ebenfalls erh&ouml;hen. Wenn es um die konkreten
                    Konditionen der Finanzierung geht, spielt &ndash; neben dem Zinsmodell &ndash; nat&uuml;rlich auch
                    die Wahl der Kreditart &nbsp;eine wesentliche Rolle.
                </p>
                <p>
                    Hast du eine monatliche Rate bestimmt, die f&uuml;r dich auch im Falle gr&ouml;&szlig;erer Ausgaben
                    bzw. steigender Zinsen leistbar ist, dann kannst du mit einem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Online-Kreditrechner
                    </Link>{" "}
                    die voraussichtliche Laufzeit deines Kredits ermitteln.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Wie viel Eigenkapital ben&ouml;tige ich bei der Hausfinanzierung?</h2>
                <p>
                    Die Frage nach der H&ouml;he des Eigenkapitals, das in die Finanzierung eingebracht werden sollte,
                    geh&ouml;rt zu den Gretchenfragen der Immobilienbranche. Eigenkapital meint die Summe an Erspartem,
                    mit der du dein zuk&uuml;nftiges Haus &ndash; neben dem{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    &ndash; finanzierst. Abgesehen von Bargeld z&auml;hlen hierzu:
                </p>
                <ul>
                    <li>Sparb&uuml;cher und -briefe</li>
                    <li>Tages- und Festgeldkonten </li>
                    <li>Aktien und Zinspapiere</li>
                    <li>Gold und Silber</li>
                    <li>Fonds bzw. Anteile an diesen</li>
                    <li>R&uuml;ckkaufswerte von Lebensversicherungen</li>
                    <li>Bausparguthaben</li>
                    <li>bebaubare Grundst&uuml;cke sowie Baumaterialien</li>
                    <li>Etwaige Darlehen von Verwandten oder Arbeitgeber*innen</li>
                </ul>
                <p>
                    Je mehr Eigenkapital du aufwenden kannst, umso wahrscheinlicher ist es, dass Banken dir einen Kredit
                    gew&auml;hren. Als Kreditnehmer*in solltest du in der Lage sein, &nbsp;20 % (im besten Fall sogar 30
                    %) an Eigenkapital in die Wohnungs- oder Hausfinanzierung &nbsp;einzubringen. Doch auch wenn du
                    &uuml;ber deutlich weniger oder gar kein Eigenkapital verf&uuml;gst, ist eine Finanzierung
                    grunds&auml;tzlich m&ouml;glich. Unsere Finanzierungsexpert*innen k&ouml;nnen dir wertvolle Tipps
                    geben, wie du deine{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonit&auml;t
                    </Link>{" "}
                    verbessern kannst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt kostenloses Erstgespr&auml;ch vereinbaren!
                    </a>
                </p>
                <hr />

                <h2>Mit welchen Nebenkosten muss ich bei der Hausfinanzierung rechnen?</h2>
                <p>
                    Bedauerlicherweise fallen beim Erwerb eines Hauses auch andere Kosten an als nur jene f&uuml;r die
                    Finanzierung. Zu diesen{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        Kaufnebenkosten
                    </Link>{" "}
                    z&auml;hlen{" "}
                </p>
                <ul>
                    <li>3% Maklerprovision</li>
                    <li>3,5% Grunderwerbsteuer</li>
                    <li>eine Geb&uuml;hr f&uuml;r die Grundbucheintragung von 1,2% </li>
                    <li>Notar*innen- bzw. Anw&auml;lt*innenkosten von 1-2%</li>
                    <li>Vermittlungs- und Bearbeitungskosten</li>
                </ul>
                <h2>Das Beste kommt zum Schluss: F&ouml;rderungen nutzen</h2>
                <p>
                    In &Ouml;sterreich werden f&uuml;r die Finanzierung und Sanierung von Wohnraum unterschiedliche
                    F&ouml;rdermodelle angeboten. Informiere dich am besten auf der website des Bundeslandes, in dem du
                    dein neues Haus kaufen willst, welche Optionen f&uuml;r dich in Frage kommen.{" "}
                </p>
                <p>
                    Oder melde dich bei miracl: Gerne helfen dir unsere Finanzierungsexpert*innen im Zuge eines
                    pers&ouml;nlichen Online-Gespr&auml;chs bei der Suche nach passenden F&ouml;rderungen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratungsgespr&auml;ch vereinbaren!
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"hausfinanzierung"}></BreadcrumbList>
            <ArticleStructuredData
                page={"hausfinanzierung"}
                heading={"Hausfinanzierung – Die Basics einfach erklärt"}
            />
        </Layout>
    );
};

export default Hausfinanzierung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.hausfinanzierung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
